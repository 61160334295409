import { render, staticRenderFns } from "./_tower.vue?vue&type=template&id=b0eb448e&scoped=true&lang=pug"
import script from "./_tower.vue?vue&type=script&lang=coffee"
export * from "./_tower.vue?vue&type=script&lang=coffee"
import style0 from "./_tower.vue?vue&type=style&index=0&id=b0eb448e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0eb448e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlocksList: require('/opt/build/repo/site/components/globals/blocks/list.vue').default})
